
export const isTouchScreen = () => {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
};

export const get_random = (array) => {
    const i = Math.floor(Math.random() * array.length);
    return `assets/imgs/${array[i]}`;
};

export const showMessage = (text) => {
    const container = document.getElementById('messages');
    if (container) {
        const newMessage = document.createElement('div');
        newMessage.classList.add('message');
        newMessage.innerHTML = text;
        container.appendChild(newMessage);
        setTimeout(() => {
            newMessage.style.opacity = '0';
            setTimeout(() => {
                container.removeChild(newMessage);
            }, 1000);
        }, 5000);
    }
};